import { SESSION_ID_KEY, LAST_ACTIVITY_TIME, MAX_INACTIVITY_DURATION, ACTIVITY_EVENTS, TOTAL_TABS_KEY } from './Constants';

export function getISO8601String(givenDate: Date): string {
    var dateString = givenDate.toISOString();
    var dateWithoutZulu = dateString.substring(0, dateString.length - 1);
    // Computing the TZ offset to GMT String base +0530 / +0800 / -0300 etc..,
    function z(n) { return (n < 10 ? '0' : '') + n; }
    var offset = new Date().getTimezoneOffset();
    var sign = offset < 0 ? '+' : '-';
    offset = Math.abs(offset);
    return dateWithoutZulu + sign + z(offset / 60 | 0) + z(offset % 60);
}

const GUID_REGEX = /^[0-9a-f]{8}-(?:[0-9a-f]{4}-){3}[0-9a-f]{12}$/i;

export function isGUID(maybeGuid: string): boolean {
    let m = maybeGuid.match(/^\s*\(([0-9a-f-]+)\)\s*$/i);
    if (!m) m = maybeGuid.match(/^\s*\{([0-9a-f-]+)\}\s*$/i);
    if (!m) m = maybeGuid.match(/^\s*([0-9a-f-]+)\s*$/i);
    const s = m ? m[1] : maybeGuid;
    const isok = !!s.match(GUID_REGEX);

    if (isok)
        return true;

    return false;
}


////#region Guid generator
export var Guid = {
    create: function (format?:string) {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }

        var tmpGuid = (format == "N")
                    ? s4() + s4() + s4() + s4() + s4() + s4() + s4() + s4()
                    : s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
        return tmpGuid;
    },
    empty: function (format?:string) {
        if (format == "N") {
            return "00000000000000000000000000000000";
        } else {
            return "00000000-0000-0000-0000-000000000000";
        }
    }
};
////#endregion


export class SessionManager {    
    //This will be used if the localstorage is not available
    private static sessionId: string = null;
    private static refreshTimer: number;

    static init(){
        Logger.log("Session Manager init called");
        //Subscribe to all the activities which ensures that the logged in user is active
        try {
            SessionManager.addEventListeners();
    }
    catch(error){
        Logger.log("Error occured while adding the eventlisteners, this might be a case in mobile application "+error);
    }
    try{
            let tabs = Number(Storage.getItem(TOTAL_TABS_KEY));
                if(tabs == null || tabs < 1){
                    //This is the first tab opened
                    Storage.setItem(TOTAL_TABS_KEY, "1");
                    SessionManager.refreshSession();
                    Storage.setItem(LAST_ACTIVITY_TIME, Date.now().toString());
                    Logger.log("First tab opened");
                }
                else{
                    //This is in case of multiple tab scenario
                    if(SessionManager.timeHasElapsed()){
                        SessionManager.refreshSession();
                    }
                    else {
                        SessionManager.loadSessionFromStorage();
                    }
                    Storage.setItem(TOTAL_TABS_KEY, (tabs+1).toString());
                    Storage.setItem(LAST_ACTIVITY_TIME, Date.now().toString());
                    SessionManager.setSessionId(this.getValidSessionId());
                    Logger.log("More than one tab opened!!! increasing the tab count in storage");
                }
    }
    catch(Exception){
        Logger.log("There is a exception in SessionManager init: "+Exception);
    }    

    SessionManager.refreshTimer = Number(setInterval(SessionManager.checkSessionValidity, MAX_INACTIVITY_DURATION));
}

static checkSessionValidity(){
    if(SessionManager.timeHasElapsed()){
        SessionManager.refreshSession();
    }
    clearInterval(SessionManager.refreshTimer);
}

static addEventListeners(){
    ACTIVITY_EVENTS.forEach(function(eventName) {
        Logger.log("The document object exists, registering the events callback for activity events");
        addEventListener(eventName, SessionManager.activity, true);   
        //Event before the page is unloaded so that the sessionId is removed from the localstorage
        addEventListener('beforeunload', SessionManager.removeSessionFromStorage, true);          
    });
}

 static removeEventListeners(){
     ACTIVITY_EVENTS.forEach(function(eventName) {
         removeEventListener(eventName, SessionManager.activity, true);
    });
}

    static removeSessionFromStorage(){
        SessionManager.removeEventListeners();
        //Removing all the session details if last tab is getting closed
        if(Storage.storageExists()){
        if(Number(Storage.getItem(TOTAL_TABS_KEY)) == 1){
            Storage.removeItem(TOTAL_TABS_KEY);
            Storage.removeItem(SESSION_ID_KEY);
            Storage.removeItem(LAST_ACTIVITY_TIME);
        }
        else
        {
            //Decrement the total tab numbers count if there is a unload event
            let tabs = Number(Storage.getItem(TOTAL_TABS_KEY)) - 1;
            Storage.setItem(TOTAL_TABS_KEY, tabs.toString());
        }
        }        
    }
    
    /**
     * The activity() function is called whenever there is any changes made on the screen, ACTIVITY_EVENTS includes list of events which trigger this
     */
   static activity(){ 
        try{  
            if(!SessionManager.sessionIdIsValid() || SessionManager.timeHasElapsed()){
                //If the user is in-active for more than MAX_INACTIVITY_DURATION then we create a new session id
                SessionManager.refreshSession();                
            }             
        }
        catch(Exception){
            Logger.log("There was a exception in Activity method: "+Exception);
        }
        Storage.setItem(LAST_ACTIVITY_TIME, Date.now().toString());
        clearInterval(SessionManager.refreshTimer);
        SessionManager.refreshTimer = Number(setInterval(SessionManager.checkSessionValidity, MAX_INACTIVITY_DURATION));
        Logger.log("timer is reset again");
    }

    static getValidSessionId(){
         if(SessionManager.timeHasElapsed() || !SessionManager.sessionIdIsValid()){
             SessionManager.refreshSession();
         }
        return SessionManager.sessionId;
    }

    static sessionIdIsValid(){
        if(SessionManager.sessionId != null && SessionManager.sessionId != undefined){
            return true;
        }   
        return false;   
    }

    static timeHasElapsed(){
        if(Storage.storageExists()){
            let now = Date.now();
            let timespan = now - Number(Storage.getItem(LAST_ACTIVITY_TIME));
            Logger.log("Time difference: "+timespan);
            return timespan >= MAX_INACTIVITY_DURATION;
        }        
    }

    static loadSessionFromStorage(){
        if(Storage.storageExists()){
            SessionManager.setSessionId(Storage.getItem(SESSION_ID_KEY));
        }
    }

    static refreshSession(){
        let newSessionId = Guid.create();
        SessionManager.setSessionId(newSessionId);
        Storage.setItem(LAST_ACTIVITY_TIME, Date.now().toString());
    }

    static setSessionId(sessionId){
        SessionManager.sessionId = sessionId;
        if(Storage.storageExists()){
            Storage.setItem(SESSION_ID_KEY, sessionId);
            Storage.setItem(LAST_ACTIVITY_TIME, Date.now().toString());
        }
    }
}


/**
 * Storage functions localstorage/sessionstorage, mobile scenarios usually do not have mutliple tabs 
 * Mobile scenarios will be using the static variables of the SessionManager class
 */
export class Storage{
    static setItem(key: string, value: string){
        let verifiedStorage = Storage.getVerifiedStorage();
        if(verifiedStorage!=null){
            verifiedStorage.setItem(key, value);
        }
    }

    static getItem(key:string){
        let verifiedStorage = Storage.getVerifiedStorage();
        if(verifiedStorage!=null){
            return verifiedStorage.getItem(key);
        }        
    }

    static removeItem(key: string){
        let verifiedStorage = Storage.getVerifiedStorage();
        if(verifiedStorage!=null){
            verifiedStorage.removeItem(key);
        } 
    }

    static getVerifiedStorage(): any{
        //Preferred to have localstorage so that multiple tab will have the same session id
        let verifiedStorage = null;
        try{            
            if(localStorage!=null) {
                Logger.log("Found local storage!!!");
                verifiedStorage = localStorage;
            }            
            if(verifiedStorage == null && sessionStorage!=null) {
                Logger.log("local storage not found session id cannot be stored across the tabs");
                verifiedStorage = sessionStorage;
            }       
        //Mostly incase of mobile scenario where sessionId is handled by static program variable 
        if(verifiedStorage == null){
            Logger.log("No suitable storage available, sessionID may not be handled properly"); 
        }           
        return verifiedStorage;
    }
    catch(Exception){
        Logger.log("There was a exception: "+Exception);
        return null;
    }
    }

    static storageExists(): boolean{
        try{            
            if(localStorage!=null || sessionStorage!=null) {
                return true;
            } 
            else{
                return false;
            }
        }
        catch(Exception){
            Logger.log("There was a exception: "+Exception); 
            return false;
        }
    }
}


/**
 * Helper functions to display the console logs
 */
export class Logger{
    static enableDevLogs: boolean = false;
    static setDevConfig(enableLogs: boolean){
        this.enableDevLogs = enableLogs;
    }
    static log(message: string){
        if(this.enableDevLogs){
            console.log(message);
        }
    }
}
