import { ComponentType, EventName, ItEventType, ActionType, TaskStatus, ActionStatus } from '../utils/Enum';

/**
 * FeatureUsageEventV2 is used to populate heart-metrics related data
 * featureName - Name of the functionality
 * subFeatureName - Sub feature name
 * actionName - actionName like create/rupdate etc
 * eventName - Is a Enum (EventName)
 * actionType - Is a Enum (ActionType)
 * componentType - Is a Enum (ComponentType)
 * componentUri -
 * userRoleName - Logged in user role name
 * entityId - The reference id of the operation for ex: orderId/transactionId
 * templateType - This is for Internal use, field used to identify the model
 * startDateTime - Event start DateTime
 * endDateTime - Event end DateTime
 * experienceResult - Task sucess or failure
 * experimentationConfigId - Flighting id
 * experimentationConfigName - Flighting name
 * experimentationConfigResult - 
 * capabilityType - Capability type
 * isEmbeddedExperience - Is embedded experience
 * embeddedExpereinceName - Embedded expereince name
 */
export  class FeatureUsageEventV2 {
    //declaring the attributes which will be populated by user when they use the SDK
    private duration: number;
    private componentUri: string;
    private componentType: ComponentType;
    private userRoleName: string;
    private startDateTime: Date;
    private endDateTime: Date;
    private templateType: string;
    private featureName: string;
    private subFeatureName: string;
    private actionName: string;
    private eventName:EventName;
    private eventType:ItEventType;
    private actionType:ActionType;
    private experimentationConfigId:string;
    private experimentationConfigName:string;
    private experimentationConfigResult:boolean;
    private entityId: string;
    private capabilityType: string;
    private isEmbeddedExperience: boolean;
    private embeddedExpereinceName: string;
    private taskName: string;
    private taskStatus: TaskStatus;
    private actionStatus: ActionStatus;
    
    constructor(featureName: string, actionType: ActionType, actionName: string,eventName: EventName, componentType: ComponentType){
        this.featureName = featureName;
        this.actionName = actionName;
        this.eventName = eventName;
        this.actionType = actionType;
        this.componentType = componentType;  
        this.eventType = ItEventType.FeatureUsageEvent;     
    }

    public get TaskName(): string {
        return this.taskName;
    }

    public set TaskName(value: string) {
        this.taskName = value;
    }

    public get TaskStatus(): TaskStatus {
        return this.taskStatus;
    }

    public set TaskStatus(value: TaskStatus) {
        this.taskStatus = value;
    }

    public get ActionStatus(): ActionStatus {
        return this.actionStatus;
    }

    public set ActionStatus(value: ActionStatus) {
        this.actionStatus = value;
    }

    public get CapabilityType(): string {
        return this.capabilityType;
    }

    public set CapabilityType(value: string) {
        this.capabilityType = value;
    }

    public get EmbeddedExpereinceName(): string {
        return this.embeddedExpereinceName;
    }

    public set EmbeddedExpereinceName(value: string) {
        this.embeddedExpereinceName = value;
    }

    public set IsEmbeddedExperience(value: boolean) {
        this.isEmbeddedExperience= value;
    }

    public get IsEmbeddedExperience(): boolean {
        return this.isEmbeddedExperience;
    }

    public get EventName(): EventName {
        return this.eventName;
    }

    public set EventName(value: EventName) {
        this.eventName = value;
    }

    public get EventType(): ItEventType {
        return this.eventType;
    }

    public set EventType(value: ItEventType) {
        this.eventType = value;
    }

    public get Duration(): number {
        return this.duration;
    }

    public set Duration(value: number) {
        this.duration = value;
    }

    public get FeatureName(): string {
        return this.featureName;
    }

    public set FeatureName(value: string) {
        this.featureName = value;
    }

    public get SubFeatureName(): string {
        return this.subFeatureName;
    }

    public set SubFeatureName(value: string) {
        this.subFeatureName = value;
    }

    public get ActionName(): string {
        return this.actionName;
    }

    public set ActionName(value: string) {
        this.actionName = value;
    }

    public set ActionType(value: ActionType) {
        this.actionType = value;
    }

    
    public get ActionType(): ActionType {
        return this.actionType;
    }

    public set ExperimentationConfigId(value: string) {
        this.experimentationConfigId = value;
    }

    public get ExperimentationConfigId(): string {
        return this.experimentationConfigId;
    }

    public set ExperimentationConfigName(value: string) {
        this.experimentationConfigName= value;
    }

    public get ExperimentationConfigName(): string {
        return this.experimentationConfigName;
    }

    public set ExperimentationConfigResult(value: boolean) {
        this.experimentationConfigResult= value;
    }

    public get ExperimentationConfigResult(): boolean {
        return this.experimentationConfigResult;
    }

    public get TemplateType(): string {
        return "Internal.FeatureUsageEventV2";
    }

    public get ComponentType(): ComponentType {
        return this.componentType;
    }

    public set ComponentType(value: ComponentType) {
        this.componentType = value;
    }   

    public get UserRoleName(): string {
        return this.userRoleName;
    }

    public set UserRoleName(value: string) {
        this.userRoleName = value;
    }

    public get StartDateTime(): Date {
        return this.startDateTime;
    }

    public set StartDateTime(value: Date) {
        this.startDateTime = value;
    }

    public get EndDateTime(): Date {
        return this.endDateTime;
    }

    public set EndDateTime(value: Date) {
        this.endDateTime = value;
    }

    public get ComponentUri(): string {
        return this.componentUri;
    }

    public set ComponentUri(value: string) {
        this.componentUri = value;
    }

    public get EntityId(): string{
        return this.entityId;
    }

    public set EntityId(value: string){
        this.entityId = value;
    }

    public getFeatureUsageName(eventProperties: any){
        if (eventProperties.SubFeatureName != null && eventProperties.SubFeatureName !== "" && eventProperties.SubFeatureName !== undefined)
            return `${eventProperties.FeatureName}.${eventProperties.SubFeatureName}-${eventProperties.ActionName}-${eventProperties.EventName}-${eventProperties.ActionType}`;
        else
            return `${eventProperties.FeatureName}-${eventProperties.ActionName}-${eventProperties.EventName}-${eventProperties.ActionType}`;
    }

    public IsValidEvent(): boolean {
        if (!this.eventName || 0 === this.eventName.length) {
            return false;
        }
        else {
            return true;
        }
    }
}   
