import { ApplicationInsights, ITelemetryPlugin, IConfiguration, Exception } from '@microsoft/applicationinsights-web';
import { ITelemetryItem } from "@microsoft/applicationinsights-core-js";
import * as Common from "@microsoft/applicationinsights-common";
import {FeatureUsageEventV2} from './contracts/FeatureUsageEventV2';
import { BusinessProcessEvent } from './contracts/BusinessProcessEvent';
import { SystemEvent } from './contracts/SystemEvent';
import { EnvironmentValues } from './contracts/EnvironmentValues';
import { getISO8601String, Logger, isGUID } from './utils/utils';
import { Correlator } from './config/Correlator';
import { ComponentType, ItEventType, ActionType, EventName, TaskStatus, ActionStatus } from './utils/Enum';
import { HttpUtil } from './utils/HttpUtil';
import { ConstantStrings, ValueNotAvailable } from './utils/Constants';
import { ICorelator } from './interfaces/ICorelator';
import { XMLHttpRequestMonitor } from './config/XMLHttpRequestMonitor';

export var ExtensionVersion = "npm:3.0.1";

export class AppInsights {
    ItTelemetryConfig: any;
    environmentData: any;
    appInsights: ApplicationInsights;
    userContext: string = null;
    /**
     * Creates Applications Insights Object and adds telemetry initializer to add service tree details. 
     * If you want to customize the application insights object creation then please use InitializeTelemetryWithConfiguration.
     * @param environmentName - Your application environment
     * @param iKey - Application insights instrumentation key
     * @param serviceOffering - service tree information
     * @param serviceLine - service tree information
     * @param service - service tree information 
     * @param componentName - service tree information 
     * @param componentId - service tree information 
     * @param ictoid - service tree information 
     * @param plugins - plugin whch will be made part of telemetryconfiguration
     * @param enableLogs - console logs will be displayed if set to true
     */
    public InitializeTelemetry(environmentName: string, iKey: string, serviceOffering: string, serviceLine: string, service: string, componentName: string, componentId: string, ictoid: string, plugins?: ITelemetryPlugin[], enableLogs?: boolean,
        randomizationUnitId?: string,assignmentContext?: string,experimentId?: string
        ) {    
        var appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: iKey,
				maxAjaxCallsPerView: -1,
                isCookieUseDisabled: true,
                disableFetchTracking: false,
                extensions: plugins
            }
        });        

        Logger.setDevConfig(enableLogs);

        this.environmentData = new EnvironmentValues(environmentName, serviceOffering, serviceLine, 
            service, componentName, componentId, ictoid,randomizationUnitId,assignmentContext,experimentId);
        
        appInsights.loadAppInsights();
        this.appInsights = appInsights;
        this.AddMsitEnvironmentInitializer(this.appInsights, this.environmentData);
        return this.appInsights;
    }

    /**
     * This method accepts the environment values in the form of object, and provides more flexibility on IConfiguration
     * @param environmentValue - Service tree information
     * @param configuration - Telemetry configuration
     */
    public InitializeTelemetryWithConfiguration(environmentValue: EnvironmentValues, configuration:  IConfiguration & Common.IConfig, enableLogs?: boolean){
        var appInsights = new ApplicationInsights({
            config: configuration
        });
        Logger.setDevConfig(enableLogs);
        this.environmentData = environmentValue;
        this.appInsights = appInsights;
        appInsights.loadAppInsights();
        this.AddMsitEnvironmentInitializer(this.appInsights, this.environmentData);
        return this.appInsights
    }

public TrackPageview(properties?: Object) {
    this.appInsights.trackPageView({properties: properties});
}

public TrackEvent(eventName: string, properties?: Object, measurements?: any) {
    if (this.appInsights != null && eventName != null) {
        this.appInsights.trackEvent({ name: eventName, properties: properties, measurements: measurements });
    }
}

public TrackTrace(message: string, severityLevel?: any, properties?: Object) {
    if (this.appInsights != null && message != null) {
        this.appInsights.trackTrace({ message: message, properties: properties, severityLevel: severityLevel });
    }
}

public TrackException(error: Error, severityLevel?: any, properties?: Object, measurements?: any) {
    if (this.appInsights != null && error != null) {
        this.appInsights.trackException({ error: error, severityLevel: severityLevel, properties: properties, measurements: measurements });
    }
}

public TrackFeatureUsageEventV2(eventData: FeatureUsageEventV2, properties?: Object, measurements?: any) {
    if (this.appInsights != null && eventData != null) {
            properties = this.BuildFeatureEventPropertiesV2(eventData, properties);
            let featureUsageEventName = eventData.getFeatureUsageName(properties);
            this.appInsights.trackEvent({ name: featureUsageEventName, properties: properties, measurements: measurements });            
    } 
}

public TrackBusinessProcessEvent(eventData: BusinessProcessEvent, properties?: Object, measurements?: any) {
    if (this.appInsights != null && eventData != null) {
        var isTrackable = this.isValidEvent(eventData, properties);

        if (isTrackable) {
            properties = this.BuildBusinessEventProperties(eventData, properties);
            this.appInsights.trackEvent({ name: eventData.BusinessProcessName, properties: properties, measurements: measurements });
        }
        else {
            throw "One or more attributes in the event Business Process Event are not valid";
        }
    }
}

public TrackSystemEvent(eventData: SystemEvent, properties?: Object, measurements?: any) {
    if (this.appInsights != null && eventData != null) {
        var isTrackable = this.isValidEvent(eventData, properties);

        if (isTrackable) {
            properties = this.BuildSystemEventProperties(eventData, properties);
            this.appInsights.trackEvent({ name: eventData.BusinessProcessName, properties: properties, measurements: measurements });
        }
        else {
            throw "One or more attributes in the event System Event are not valid";
        }
    }
}

/**
 * StartFeatureUsageEvent - helps to track the duration of a feature between StartFeatureUsageEvent & EndFeatureUsageEvent
 * @param eventData - FeatureUsageEvent that needs to be tracked
 */
public StartFeatureUsageEvent(eventData: FeatureUsageEventV2){
    if(this.appInsights!==null && eventData!= null){
        this.appInsights.startTrackEvent(eventData.getFeatureUsageName(eventData));        
    }        
}

/**
 * EndFeatureUsageEvent - helps to track the duration of a feature between StartFeatureUsageEvent & EndFeatureUsageEvent
 * @param eventData - FeatureUsageEvent that needs to be tracked
 * @param properties - Additional properties
 */
public EndFeatureUsageEvent(eventData: FeatureUsageEventV2, properties?: Object){
    if(this.appInsights!==null && eventData!= null){
        this.appInsights.stopTrackEvent(eventData.getFeatureUsageName(eventData),this.BuildFeatureEventPropertiesV2(eventData, properties));       
    }        
}

    public StartTask(eventData: FeatureUsageEventV2) {
        if (this.appInsights !== null && eventData != null) {
            eventData.TaskStatus = TaskStatus.Start;
            this.TrackFeatureUsageEventV2(eventData);
        }
    }
    public EndTask(eventData: FeatureUsageEventV2) {
        if (this.appInsights !== null && eventData != null) {
            eventData.TaskStatus = TaskStatus.Completed;
            this.TrackFeatureUsageEventV2(eventData);
        }
    }




/**
 * Adds Environment and Service Tree metadata details to the requested application insights object. 
 * If you are using InitializerTelemetry then please use the overload version of this method.
 * @param appInsights - Your own application insights object, this will not use the default application insights object provided by UTP
 * @param environmentValue - Your own environment values, this will over write the environment values given in the intializeTelemetry or initializeTelemetryWithConfiguration
 * @param correlator - Correlator implementation of your own or UTP exposed Correlator
 */
public AddMsitTelemetryInitializers(appInsights: ApplicationInsights, environmentValue: EnvironmentValues, correlator: ICorelator):any;
/**
 * Use this overload if you want to use the application insights instance of UTP and environment values will be used from the one given in intializeTelemetry or initializeTelemetryWithCOnfiguration,
 * if you want to override the application insights object and environment values then use the other overload of this method
 * @param correlator  - Correlator implementation of your own or UTP exposed Correlator
 */
public AddMsitTelemetryInitializers(correlator: ICorelator):any;

public AddMsitTelemetryInitializers(appInsightsOrCorrelator?: (ApplicationInsights | ICorelator), environmentValue?: EnvironmentValues, correlator?: any):any {
    var applicationInsightsObject: ApplicationInsights = null; 
    var environmentData: any = null;
    
    if(appInsightsOrCorrelator!=null){
        if(appInsightsOrCorrelator instanceof  ApplicationInsights){
            
            applicationInsightsObject = appInsightsOrCorrelator; //When customer is sending his own AI object
        }
            else{                
            correlator = appInsightsOrCorrelator; //Customer wants to use the global AI and has given the CorrelationProvider
            // var xmlAjaxRecord = new XMLHttpRequestMonitor(correlator, this.appInsights);
            }
        }
    
    if(environmentValue!= null){
        environmentData = environmentValue; //When customer is sending sepearte set of Environment values and wants to ignore the values provided in InitializeTelemetry & IntializeTelemetryWithConfiguration
    }

    if(applicationInsightsObject == null){
        applicationInsightsObject = this.appInsights;
    }

        //Attach the initializer if one of them global/local is available
        if(applicationInsightsObject!=null){ 
            Logger.log("Initializer called before emitting the event");
        
            if(environmentData!=null){
                this.AddMsitEnvironmentInitializer(applicationInsightsObject, environmentData);
            }

            if(correlator!=null){
                this.AddMsitInternalCorrelationIntializer(applicationInsightsObject, correlator);
            }
        }
    }   

    /**
     * This method is going to absolete in something, please use AddMsitTelemetryInitializers instead
     * @param appInsights  - A seperate Application insights object 
     * @param environmentData - Service tree information
     * @param correlationProvider - Correlation provider to generate xcv
     * @param subCorrelationProvider - SubCorrelation provider to generate subXcv
     */
    public AddMsitTelemetryInitializerswithSubCorrelation(appInsights: ApplicationInsights, environmentData: EnvironmentValues, correlationProvider: () => string, subCorrelationProvider: () => string) {
        appInsights.addTelemetryInitializer((envelope: ITelemetryItem) => {
            envelope.baseData.properties = envelope.baseData.properties || {};
            envelope.baseData.properties["AiAppKey"] = appInsights.config.instrumentationKey;

            if (environmentData != null) {
                envelope.baseData.properties["EnvironmentName"] = environmentData.EnvironmetName == null ? "" : environmentData.EnvironmetName;
                envelope.baseData.properties["ServiceOffering"] = environmentData.ServiceOffering == null ? "" : environmentData.ServiceOffering;
                envelope.baseData.properties["ServiceLine"] = environmentData.ServiceLine == null ? "" : environmentData.ServiceLine;
                envelope.baseData.properties["Service"] = environmentData.Service == null ? "" : environmentData.Service;
                envelope.baseData.properties["ComponentName"] = environmentData.ComponentName == null ? "" : environmentData.ComponentName;
                envelope.baseData.properties["ComponentId"] = environmentData.ComponentId == null ? "" : environmentData.ComponentId;
                envelope.baseData.properties["ItExtVersion"] = ExtensionVersion;
                envelope.baseData.properties["RandomizationUnitId"] =environmentData.RandomizationUnitId== null ?"" : environmentData.RandomizationUnitId;
                envelope.baseData.properties["AssignmentContext"] =environmentData.AssignmentContext== null ?"" : environmentData.AssignmentContext;
                envelope.baseData.properties["ExperimentId"] =environmentData.ExperimentId== null ?"" : environmentData.ExperimentId;      
            }

            var correlationId = "";
            if (correlationProvider != null)
                correlationId = correlationProvider();

            if (correlationId != null && correlationId !== "") {
                envelope.baseData.properties["XCV"] = correlationId;
            }

            var subcorrelationId = "";
            if (subCorrelationProvider != null)
                correlationId = subCorrelationProvider();

            if (subcorrelationId != null && subcorrelationId !== "") {
                envelope.baseData.properties["SubXCV"] = subcorrelationId;
            }

        });
    }

public Flush() {
    if(this.appInsights!==null && this.appInsights!==undefined){
        this.appInsights.flush();
    }    
}


/**
 * Private method not exposed to customer, used to attach service tree information to all the logs
 * @param appInsights 
 * @param environment 
 */
private AddMsitEnvironmentInitializer(appInsights: ApplicationInsights, environment: EnvironmentValues){
    appInsights.addTelemetryInitializer((envelope: any) => {        
        if(envelope !== undefined && envelope.baseData !== undefined){
            try {
            envelope.baseData.properties = envelope.baseData.properties || {};
            envelope.baseData.properties["AiAppKey"] = appInsights.config.instrumentationKey;
            if (environment != null) {
               this.populateEnvironmentValues(envelope.baseData.properties, environment);
                }
            }
            catch (ex) {
                Logger.log("Exception occured :"+ex);
              }
        }
    });
}



/**
 * Stores XCV and SubXCV
 * @param appInsights 
 * @param correlator 
 */
private AddMsitInternalCorrelationIntializer(appInsights: ApplicationInsights, correlator: ICorelator){
    appInsights.addTelemetryInitializer((envelope: any) => { 
    try {
        if (correlator != null) {
            Logger.log("Correlation provided by the client application");            
            let correlationId = correlator.getCorrelationId();
            let subcorrelationId = correlator.getSubCorrelationId();            
            if(correlationId !== null && correlationId !== undefined && correlationId !== ""){
                envelope.baseData.properties["XCV"] = correlationId;   
            }  
            if(subcorrelationId !== null && subcorrelationId !== undefined && subcorrelationId !== ""){
                envelope.baseData.properties["SubXCV"] = subcorrelationId;   
            }   
            if(envelope.baseData.properties["ActionStatus"] === ActionStatus.Failed){
                correlator.renewSubCorrelationId();
            }                
        }
    }
    catch (ex) {
        Logger.log("Exception occured :"+ex);
        }
    });
}

private PopulateCommonSchemaValues(appInsights: ApplicationInsights, properties?: Object) {
    properties = properties || {};
    properties["AiAppKey"] = appInsights.config.instrumentationKey == null ? "" : appInsights.config.instrumentationKey;
    properties["EnvironmentName"] = this.environmentData.EnvironmetName == null ? "" : this.environmentData.EnvironmetName;
    properties["ServiceOffering"] = this.environmentData.ServiceOffering == null ? "" : this.environmentData.ServiceOffering;
    properties["ServiceLine"] = this.environmentData.ServiceLine == null ? "" : this.environmentData.ServiceLine;
    properties["Service"] = this.environmentData.Service == null ? "" : this.environmentData.Service;
    properties["ComponentName"] = this.environmentData.ComponentName == null ? "" : this.environmentData.ComponentName;
    properties["ComponentId"] = this.environmentData.ComponentId == null ? "" : this.environmentData.ComponentId;
    properties["ItExtVersion"] = ExtensionVersion;
    properties["RandomizationUnitId"] =this.environmentData.RandomizationUnitId== null ?"" : this.environmentData.RandomizationUnitId;
    properties["AssignmentContext"] =this.environmentData.AssignmentContext== null ?"" : this.environmentData.AssignmentContext;
    properties["ExperimentId"] =this.environmentData.ExperimentId== null ?"" : this.environmentData.ExperimentId; 
    return properties;
}

    private populateEnvironmentValues(props: any, environmentValue: EnvironmentValues){
        Logger.log("Adding Environment data to the envelope");
        props["EnvironmentName"] = this.environmentData.EnvironmetName == null ? "" : this.environmentData.EnvironmetName;
        props["ServiceOffering"] = this.environmentData.ServiceOffering == null ? "" : this.environmentData.ServiceOffering;
        props["ServiceLine"] = this.environmentData.ServiceLine == null ? "" : this.environmentData.ServiceLine;
        props["Service"] = this.environmentData.Service == null ? "" : this.environmentData.Service;
        props["ComponentName"] = this.environmentData.ComponentName == null ? "" : this.environmentData.ComponentName;
        props["ComponentId"] = this.environmentData.ComponentId == null ? "" : this.environmentData.ComponentId;
        if (this.environmentData.IctoId != null && this.environmentData.IctoId !== "") {
            props["IctoId"] = this.environmentData.IctoId;
        }
        props["ItExtVersion"] = ExtensionVersion;
        props["RandomizationUnitId"] =this.environmentData.RandomizationUnitId== null ?"" : this.environmentData.RandomizationUnitId;
        props["AssignmentContext"] =this.environmentData.AssignmentContext== null ?"" : this.environmentData.AssignmentContext;
        props["ExperimentId"] =this.environmentData.ExperimentId== null ?"" : this.environmentData.ExperimentId; 
    }
    
    private populateCorrelationAttributes(props: any, correlator: ICorelator){
        if (correlator != null) {
            Logger.log("Correlation provided by the client application");
                let correlationId = correlator.getCorrelationId();
                let subcorrelationId = correlator.getSubCorrelationId();
                if(correlationId !== null && correlationId !== undefined && correlationId !== ""){
                    props["XCV"] = correlationId;   
                }  
                if(subcorrelationId !== null && subcorrelationId !== undefined && subcorrelationId !== ""){
                    props["SubXCV"] = subcorrelationId;   
                }                   
            }                                 
    }

//added part of latest change
    private BuildFeatureEventPropertiesV2(eventData: FeatureUsageEventV2, properties?: Object) {
        var eventProperties;
        eventProperties = properties || {};
        
        eventProperties["ComponentType"] = (eventData.ComponentType !== undefined && eventData.ComponentType != null) ? ComponentType[eventData.ComponentType].toString() : "";             
        eventProperties["ActionType"] = eventData.ActionType !== undefined && eventData.ActionType != null ? ActionType[eventData.ActionType].toString() : ValueNotAvailable;   
        eventProperties["EventType"] = eventData.EventType !== undefined && eventData.EventType != null ? ItEventType[eventData.EventType].toString() : "";
        eventProperties["TemplateType"] = eventData.TemplateType === undefined || eventData.TemplateType === null ? "" : eventData.TemplateType;
        eventProperties["MsitPartB"] = "true";
        eventProperties["FeatureName"] = eventData.FeatureName === undefined || eventData.FeatureName === null || eventData.FeatureName === "" ? ValueNotAvailable : eventData.FeatureName;
        eventProperties["EventName"] = eventData.EventName === undefined || eventData.EventName === null  ? ValueNotAvailable : eventData.EventName;
        eventProperties["SubFeatureName"] = eventData.SubFeatureName === undefined || eventData.SubFeatureName === null ? "" : eventData.SubFeatureName;
        eventProperties["ActionName"] = eventData.ActionName === undefined || eventData.ActionName === null || eventData.ActionName === "" ? ValueNotAvailable : eventData.ActionName;
        eventProperties["TaskStatus"] = eventData.TaskStatus === undefined || eventData.TaskStatus === null || eventData.TaskStatus === TaskStatus.NA ? ValueNotAvailable : eventData.TaskStatus;
        eventProperties["ActionStatus"] = eventData.ActionStatus === undefined || eventData.ActionStatus === null || eventData.ActionStatus === ActionStatus.NA ? ValueNotAvailable : eventData.ActionStatus;
        if (eventData.TaskName !== undefined && eventData.TaskName !== null && eventData.TaskName !== "") {
            eventProperties["TaskName"] = eventData.TaskName;
        }
        
        if(eventData.Duration !== undefined && eventData.Duration !== null){
            eventProperties["Duration"] = eventData.Duration;
        }        

        if(eventData.ComponentUri !== undefined && eventData.ComponentUri !== null){
            eventProperties["ComponentUri"] = eventData.ComponentUri;
        }  
        if(eventData.UserRoleName !== undefined && eventData.UserRoleName !== null){
            eventProperties["UserRoleName"] = eventData.UserRoleName; 
        }         
        if(eventData.StartDateTime !== undefined && eventData.StartDateTime !== null){
            eventProperties["StartDateTime"] = eventData.StartDateTime.toUTCString();
        }
        if(eventData.EndDateTime !== undefined && eventData.EndDateTime !== null){
            eventProperties["EndDateTime"] = eventData.EndDateTime.toUTCString();
        }
        if(eventData.EntityId !== undefined && eventData.EntityId !== null){
            eventProperties["EntityId"] = eventData.EntityId;
        }
        if(eventData.ExperimentationConfigId !== undefined && eventData.ExperimentationConfigId !== null && eventData.ExperimentationConfigId !== ""){
            eventProperties["experimentationConfigId"] = eventData.ExperimentationConfigId;
        }
        if(eventData.ExperimentationConfigName !== undefined && eventData.ExperimentationConfigName !== null && eventData.ExperimentationConfigName !== ""){
            eventProperties["experimentationConfigName"] = eventData.ExperimentationConfigName;
        }        
        if(eventData.ExperimentationConfigResult !== undefined && eventData.ExperimentationConfigResult !== null){
            eventProperties["experienceResult"] = eventData.ExperimentationConfigResult;
        } 
        
        if(eventData.CapabilityType !== undefined && eventData.CapabilityType !== null){
            eventProperties["CapabilityType"] = eventData.CapabilityType;
        }
        if(eventData.IsEmbeddedExperience !== undefined && eventData.IsEmbeddedExperience !== null){
            eventProperties["IsEmbeddedExperience"] = eventData.IsEmbeddedExperience;
        }
        if(eventData.EmbeddedExpereinceName !== undefined && eventData.EmbeddedExpereinceName !== null){
            eventProperties["EmbeddedExpereinceName"] = eventData.EmbeddedExpereinceName;
        }
    
        return eventProperties;
    }

    private BuildBusinessEventProperties(eventData: BusinessProcessEvent, properties?: Object) {
        var eventProperties;
        eventProperties = properties || {};

        eventProperties["ActionUri"] = eventData.ActionUri === undefined ? "" : eventData.ActionUri;
        eventProperties["AppAction"] = eventData.AppAction === undefined ? "" : eventData.AppAction;
        eventProperties["ComponentType"] = (eventData.ComponentType !== undefined && eventData.ComponentType != null) ? ComponentType[eventData.ComponentType].toString() : "";
        eventProperties["TargetEntityKey"] = eventData.TargetEntityKey === undefined ? "" : eventData.TargetEntityKey;
        eventProperties["UserRoleName"] = eventData.UserRoleName === undefined ? "" : eventData.UserRoleName;
        eventProperties["XCV"] = eventData.Xcv === undefined ? "" : eventData.Xcv;
        eventProperties["EventType"] = eventData.EventType !== undefined && eventData.EventType != null ? ItEventType[eventData.EventType].toString() : "";
        eventProperties["StartDateTime"] = eventData.StartDateTime === undefined || eventData.StartDateTime === null ? "" : eventData.StartDateTime.toUTCString();
        eventProperties["EndDateTime"] = eventData.EndDateTime === undefined || eventData.EndDateTime === null ? "" : eventData.EndDateTime.toUTCString();
        eventProperties["SenderId"] = eventData.SenderId === undefined ? "" : eventData.SenderId;
        eventProperties["ReceiverID"] = eventData.ReceiverId === undefined ? "" : eventData.ReceiverId;
        eventProperties["MessageId"] = eventData.MessageId === undefined ? "" : eventData.MessageId;
        eventProperties["EventOccurrenceTime"] = eventData.EventOccurrenceTime === undefined || eventData.EventOccurrenceTime === null || isNaN(eventData.EventOccurrenceTime.getDate()) ? "" : getISO8601String(new Date(eventData.EventOccurrenceTime.toString()));
        eventProperties["BusinessProcessName"] = eventData.BusinessProcessName === undefined ? "" : eventData.BusinessProcessName;
        eventProperties["TemplateType"] = eventData.TemplateType === undefined || eventData.TemplateType === null ? "" : eventData.TemplateType;
        eventProperties["MsitPartB"] = "true";

        return eventProperties;
    }

    private BuildSystemEventProperties(eventData: SystemEvent, properties?: Object) {
        var eventProperties;
        eventProperties = properties || {};

        eventProperties["ActionUri"] = eventData.ActionUri === undefined ? "" : eventData.ActionUri;
        eventProperties["AppAction"] = eventData.AppAction === undefined ? "" : eventData.AppAction;
        eventProperties["ComponentType"] = (eventData.ComponentType !== undefined && eventData.ComponentType != null) ? ComponentType[eventData.ComponentType].toString() : "";
        eventProperties["TargetEntityKey"] = eventData.TargetEntityKey === undefined ? "" : eventData.TargetEntityKey;
        eventProperties["UserRoleName"] = eventData.UserRoleName === undefined ? "" : eventData.UserRoleName;
        eventProperties["XCV"] = eventData.Xcv === undefined ? "" : eventData.Xcv;
        eventProperties["EventType"] = eventData.EventType !== undefined && eventData.EventType != null ? ItEventType[eventData.EventType].toString() : "";
        eventProperties["StartDateTime"] = eventData.StartDateTime === undefined || eventData.StartDateTime === null ? "" : eventData.StartDateTime.toUTCString();
        eventProperties["EndDateTime"] = eventData.EndDateTime === undefined || eventData.EndDateTime === null ? "" : eventData.EndDateTime.toUTCString();
        eventProperties["SenderId"] = eventData.SenderId === undefined ? "" : eventData.SenderId;
        eventProperties["ReceiverID"] = eventData.ReceiverId === undefined ? "" : eventData.ReceiverId;
        eventProperties["MessageId"] = eventData.MessageId === undefined ? "" : eventData.MessageId;
        eventProperties["EventOccurrenceTime"] = eventData.EventOccurrenceTime === undefined || eventData.EventOccurrenceTime === null || isNaN(eventData.EventOccurrenceTime.getDate()) ? "" : getISO8601String(new Date(eventData.EventOccurrenceTime.toString()));
        eventProperties["BusinessProcessName"] = eventData.BusinessProcessName === undefined ? "" : eventData.BusinessProcessName;

        eventProperties["SystemMessage"] = eventData.SystemMessage === undefined || eventData.SystemMessage === null ? "" : eventData.SystemMessage;
        eventProperties["TemplateType"] = eventData.TemplateType === undefined || eventData.TemplateType === null ? "" : eventData.TemplateType;
        eventProperties["MsitPartB"] = "true";
        return eventProperties;
    }

    public isValidEvent(eventData: any, properties?: Object) {
        var config = this.ItTelemetryConfig;
        var isTrackable = eventData.IsValidEvent(false);

        if (config !== null && config !== undefined && config.active.EnableBpmValidation) {
            var bpmSpecificConfig = config.active.ReadBpmValidation(eventData.EventName);

            if (bpmSpecificConfig == null) {
                return isTrackable;
            }

            if (bpmSpecificConfig.PartBValidation) {
                isTrackable = eventData.IsValidEvent(bpmSpecificConfig.PartBValidation);
            }

            if (bpmSpecificConfig.Properties != null) {
                var keys = Object.keys(properties);
                var bpmPropertiesLocal = bpmSpecificConfig.Properties;
                var numberOfPropertiesFound = 0;

                for (var index = 0; index < bpmPropertiesLocal.length; index++) {
                    var item = bpmPropertiesLocal[index];
                    var check = keys.indexOf(item);
                    if (check >= 0) {
                        // Provided a property is found, it should have a value.
                        if (typeof properties[item] != 'undefined') {
                            numberOfPropertiesFound += 1;
                        }
                        else {
                            isTrackable = false;
                            break;
                        }
                    }
                }

                if (numberOfPropertiesFound == bpmPropertiesLocal.length && isTrackable != false) {
                    return true;
                }
                else {
                    return false;
                }
            }
        }
        else {
            return isTrackable;
        }
    }
}

export { BusinessProcessEvent };
export { EnvironmentValues };
export { ItEventType };
export { SystemEvent };
export { Correlator };
export { FeatureUsageEventV2 };
export { ComponentType };
export { EventName };
export { ActionType };
export { TaskStatus };
export { ActionStatus };