import { Guid } from '../utils/utils';
import { ICorelator } from '../interfaces/ICorelator';
import $ from "jquery";

export class Correlator implements ICorelator{
correlationId: string;
subCorelationId: string;

    constructor() {
        if(this.correlationId === null || this.correlationId === undefined){
        this.correlationId = Guid.create();
        if($!=null && $!=undefined && $.ajaxSetup!=null && $.ajaxSetup!=undefined){
            $.ajaxSetup({
                headers: { "X-CorrelationId": this.correlationId }
            });
        }       
        }
    }
    
    /*
    * method to get correation-id
    */
    getCorrelationId():string
    {
        return this.correlationId;
    }

    /*
    * method to set correation-id
    */
    setCorrelationId(newId:string)
    {
        this.correlationId = newId;
    }

    /*
    * method to renew correation-id
    */
    renewCorrelationId()
    {
        var tmp = Guid.create();
        this.setCorrelationId(tmp);
        if($!=null && $!=undefined && $.ajaxSetup!=null && $.ajaxSetup!= undefined){
            $.ajaxSetup({
                headers: { "X-CorrelationId": this.correlationId }
            });
        }
        return this.correlationId;
    }

    /*
    * method to get sub-corelation-id
    */
    getSubCorrelationId():string
    {
        return this.subCorelationId;
    }

    /*
    * method to set sub-corelation-id
    */
    setSubCorrelationId(newId:string)
    {
        this.subCorelationId = newId;
    }

    /*
    * method to renew sub-corelation-id
    */
    renewSubCorrelationId(): string
    {
        var tmp = Guid.create();
        this.setSubCorrelationId(tmp);
        return this.subCorelationId;
    }
}


