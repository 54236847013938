export const SESSION_ID_KEY = "utp.session.id"
export const LAST_ACTIVITY_TIME = "utp.last.activity.time"
export const PAGE_ID_KEY = "utp.page.id"
export const PAGE_NAME_KEY = "utp.page.name"
export const MAX_INACTIVITY_DURATION = 900000
export const ONE_SECOND_TIME_OUT = 1000
export const X_CORRELATION_ID = "X-CorrelationId"
export const ACTIVITY_EVENTS = [
    'mousedown', 'mousemove', 'keydown',
    'scroll', 'touchstart',"keypress","DOMMouseScroll","mousewheel","touchmove","MSPointerMove"
];
export const CORRELATION_EXCLUDED_DOMAINS = [
    "*.blob.core.windows.net", 
    "*.blob.core.chinacloudapi.cn",
    "*.blob.core.cloudapi.de",
    "*.blob.core.usgovcloudapi.net"];
    
export const TOTAL_TABS_KEY = "utp.total.tabs";
export const ValueNotAvailable = "NA";
export const DISABLED_PROPERTY_NAME = "Microsoft_ApplicationInsights_BypassAjaxInstrumentation";
export const ConstantStrings = {
    Configuration:{
        FeatureUsageEventV2TemplateType: 'Internal.FeatureUsageEventV2'
    }
}