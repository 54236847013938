export enum ComponentType {
    Web,
    SmartApp,
    Device,
    BackgroundProcess,
    WebService,
    Executable,
    DynamicLinkLibrary,
    JobService,
    WorkflowComponent,
    DataStore,
    Other
  }

  export enum EventName {
    PageLoad = "PageLoad",
    DropdownSelected = "DropdownSelected",
    DropdownChange = "DropdownChange",
    PageNavigation = "PageNavigation",
    CheckboxChange = "CheckboxChange",
    ListChange = "ListChange",
    ButtonClick = "ButtonClick",
    TabChange = "TabChange",
    TabNavigation = "TabNavigation",
    PanelOpened = "PanelOpened",
    PanelClosed = "PanelClosed",
    PickerSelected = "PickerSelected",
    PickerDeselected = "PickerDeselected",
    TileAdded = "TileAdded",
    TileClicked = "TileClicked",
    TileDeleted = "TileDeleted",
    TileLoaded = "TileLoaded",
    OnFocus = "OnFocus",
    OnBlur = "OnBlur",
    TextChange = "TextChange",
    Scroll = "Scroll",
    SectionRendering = "SectionRendering",
    BackEndAPICall = "BackEndAPICall",
    Rendering = "Rendering",
    NA = "NA",
    Hover = "Hover",
    LinkClicked = "LinkClicked"
}

export enum TaskStatus {
    Start = "Start",
    Completed = "Completed",
    NA = "NA"
}

export enum ActionStatus{
    Succeeded = "Succeeded",
    Failed = "Failed",
    NA = "NA"
}

export enum ActionType{
    User = "User",
    System = "System",
    Aware = "Aware"
}

export enum ItEventType {
    FeatureUsageEvent,
    BusinessProcessEvent,
    BusinessEventMessageExchange,
    BusinessEventEntityUpdate,
    BusinessEventDatabaseRecord,
    SystemEvent,
    Other
}

export enum StorageType{
    LocalStorage,
    SessionStorage
}