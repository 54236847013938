import { ItEventType, ComponentType } from '../utils/Enum';

export class BusinessProcessEvent {

    private businessProcessName: string;
    private eventName: string;
    private targetEntityKey: string;
    private actionUri: string;
    private appAction: string;
    private eventType: ItEventType;
    private componentType: ComponentType;
    private xcv: string;
    private userRoleName: string;
    private startDateTime: Date;
    private endDateTime: Date;

    private senderId: string;
    private receiverId: string;
    private messageId: string;
    private eventOccurenceTime: Date;

    private templateType: string;

    constructor(businessProcessName: string, componentType: ComponentType)
    constructor(businessProcessName: string, componentType: ComponentType, startDateTime: Date, endDateTime: Date)
    constructor(businessProcessName: string, componentType: ComponentType, startDateTime: Date, endDateTime: Date, templateType: string)
    constructor(businessProcessName: string, componentType: ComponentType, startDateTime?: Date, endDateTime?: Date, templateType?: string) {
        this.BusinessProcessName = businessProcessName;
        this.eventName = businessProcessName;
        this.ComponentType = componentType;
        this.startDateTime = startDateTime;
        this.endDateTime = endDateTime;
        if (typeof this.templateType != 'undefined') {
            this.templateType = templateType;
        }
        else {
            this.templateType = "Internal.BusinessProcessEvent";
        }
    }

    public get BusinessProcessName(): string {
        return this.businessProcessName;
    }

    public set BusinessProcessName(value: string) {
        this.businessProcessName = value;
    }

    public get TargetEntityKey(): string {
        return this.targetEntityKey;
    }

    public set TargetEntityKey(value: string) {
        this.targetEntityKey = value;
    }

    public get ActionUri(): string {
        return this.actionUri;
    }

    public set ActionUri(value: string) {
        this.actionUri = value;
    }

    public get AppAction(): string {
        return this.appAction;
    }

    public set AppAction(value: string) {
        this.appAction = value;
    }

    public get EventType(): ItEventType {
        return this.eventType;
    }

    public set EventType(value: ItEventType) {
        this.eventType = value;
    }

    public get TemplateType(): string {
        return "Internal.BusinessProcessEvent";
    }

    public get ComponentType(): ComponentType {
        return this.componentType;
    }

    public set ComponentType(value: ComponentType) {
        this.componentType = value;
    }

    public get Xcv(): string {
        return this.xcv;
    }

    public set Xcv(value: string) {
        this.xcv = value;
    }

    public get UserRoleName(): string {
        return this.userRoleName;
    }

    public set UserRoleName(value: string) {
        this.userRoleName = value;
    }

    public get StartDateTime(): Date {
        return this.startDateTime;
    }

    public set StartDateTime(value: Date) {
        this.startDateTime = value;
    }

    public get EndDateTime(): Date {
        return this.endDateTime;
    }

    public set EndDateTime(value: Date) {
        this.endDateTime = value;
    }

    public get EventName(): string {
        return this.eventName;
    }

    public set EventName(value: string) {
        this.eventName = value;
    }

    public get SenderId(): string {
        return this.senderId;
    }

    public set SenderId(value: string) {
        this.senderId = value;
    }

    public get ReceiverId(): string {
        return this.receiverId;
    }

    public set ReceiverId(value: string) {
        this.receiverId = value;
    }

    public get MessageId(): string {
        return this.messageId;
    }

    public set MessageId(value: string) {
        this.messageId = value;
    }

    public get EventOccurrenceTime(): Date {
        return this.eventOccurenceTime;
    }

    public set EventOccurrenceTime(value: Date) {
        this.eventOccurenceTime = value;
    }

    public IsValidEvent(isValid: boolean): boolean {
        if (!isValid) {
            if (!this.businessProcessName || 0 === this.businessProcessName.length) {
                return false;
            }
            else {
                return true;
            }
        }
        else {
            return ((typeof this.appAction != "undefined" && 0 !== this.appAction.length) && (typeof this.businessProcessName != "undefined" && 0 !== this.businessProcessName.length) && (typeof this.receiverId != "undefined" && 0 !== this.receiverId.length) && (typeof this.senderId != "undefined" && 0 !== this.senderId.length) && (typeof this.eventOccurenceTime != "undefined"));
        }
    }
}
